import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { companies } from "custom";
import { CustomApp } from "custom/types";
import { Cookies } from "Utils";

interface Context<T = CustomApp> {
  customApp: T | undefined;
  language: Nullable<Pick<CustomApp, "event">>;
  loading: boolean;
}

const CustomAppContext = createContext<Context | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CustomAppProvider: React.FC<Props> = ({ children }) => {
  const [customApp, setCustomApp] = useState<Context["customApp"]>(null);
  const [language, setLanguage] = useState<Context["language"]>(null);
  const [loading, setLoading] = useState(true);

  const value = useMemo(() => ({ customApp, loading, language }), [loading]);

  useLayoutEffect(() => {
    const host = Cookies.get("host") || window.location.host;

    const [, config] = companies.find(([re]) => !!host.match(re)) || [];

    if (config) {
      setCustomApp(config);
    }
    setLanguage({
      event: {
        type: {
          singular: config?.event.type.singular || "Show",
          plural: config?.event.type.plural || "Shows",
          singularWithArticle:
            config?.event.type.singularWithArticle || "a Show",
        },
        owner: {
          singular: config?.event.owner.singular || "Artist",
          plural: config?.event.owner.plural || "Artists",
          singularWithArticle:
            config?.event.owner.singularWithArticle || "an Artist",
        },
        audience: {
          singular: config?.event.audience.singular || "Fan",
          plural: config?.event.audience.plural || "Fans",
          singularWithArticle:
            config?.event.audience.singularWithArticle || "a Fan",
        },
      },
    });
    setLoading(false);
  }, []);

  return (
    <CustomAppContext.Provider value={value}>
      {children}
    </CustomAppContext.Provider>
  );
};

export const useCustomAppContext = <T = CustomApp,>(): Context<T> => {
  const context = useContext(CustomAppContext) as Context<T>;

  if (context === undefined) {
    throw new Error(
      "useCustomAppContext must be used within a CustomAppProvider"
    );
  }

  return context;
};
