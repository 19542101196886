import {
  createContext,
  useState,
  useEffect,
  FC,
  useContext,
  useLayoutEffect,
} from "react";
import { firestore } from "firebase-internal";
import LogRocket from "logrocket";
import { useUser } from "auth";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useCustomAppContext } from "./CustomAppContext";
import { SESSION_PATHS } from "Routes/Session/paths";

interface AuthContextProps {
  isArtist: boolean;
}

export const AuthContext = createContext<AuthContextProps>(undefined);

export const AuthProvider: FC = ({ children }) => {
  const { user, loading: userLoading, clearSession } = useUser();
  const [isArtist, setIsArtist] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { customApp } = useCustomAppContext();

  useEffect(() => {
    if (user?.uid) {
      if (user.isAnonymous || !user.email) {
        clearSession();
        return;
      }

      getDocs(
        query(
          collection(firestore, "setlive_events"),
          where(`memberUids`, "array-contains", user?.uid)
        )
      )
        .then((response) => {
          setIsArtist(response.size > 0);
          setLoading(false);
        })
        .catch(() => {
          setIsArtist(false);
          setLoading(false);
        });
    }
  }, [user?.uid]);

  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  useLayoutEffect(() => {
    if (!loading) {
      document.getElementById("loader")?.remove();
    }
  }, [loading]);

  const value: AuthContextProps = {
    isArtist: isArtist ?? false,
  };

  if (
    !userLoading &&
    user === null &&
    !window.location.pathname.startsWith(SESSION_PATHS.ROOT)
  ) {
    const url = new URL(
      "/signout",
      customApp?.artistAppUrl ||
        `https://${process.env.REACT_APP_FIREBASE_AUTHDOMAIN}`
    );

    url.searchParams.set("redirect", window.location.href);

    window.location.href = url.toString();
  }
  if (userLoading || loading) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
