import { Route, RouteComponentProps } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { GtbRegion } from "@max/common/thirdparty/gtb";
import { GtbCustomApp } from "@max/companies/companies/gtb/types";
import {
  Query,
  collection,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useUser } from "auth";
import { useCustomAppContext } from "Context/CustomAppContext";
import { Loader } from "Components";
import { lazy } from "react";

const ArtistEventList = lazy(() => import("Routes/Artist"));
const ArtistEvent = lazy(() => import("Routes/Artist/Event"));

export const Routes: React.FC = () => {
  const { user, claims } = useUser();
  const { customApp } = useCustomAppContext<GtbCustomApp>();
  const [regions, regionsLoading] = useCollectionData<GtbRegion>(
    query(
      collection(getFirestore(), customApp.regionsCollection)
    ) as Query<GtbRegion>
  );

  const [regionsAsAdmin, regionsAsAdminLoading] = useCollectionData<GtbRegion>(
    query(
      collection(getFirestore(), customApp.regionsCollection),
      where(`members.${user.uid}`, "!=", null)
    ) as Query<GtbRegion>
  );

  const isSystemAdmin = claims.isAdmin;
  const regionsWithAccess = isSystemAdmin ? regions : regionsAsAdmin;
  const loading = regionsLoading || regionsAsAdminLoading;

  const groupIds = regionsWithAccess?.flatMap((region) =>
    Object.keys(region.programs)
  );

  return loading ? (
    <Loader />
  ) : (
    <>
      <Route
        path="/"
        exact
        render={() => <ArtistEventList groupIds={groupIds} />}
      />
      <Route
        path="/:eventId"
        render={(props: RouteComponentProps<{ eventId: string }>) => (
          <ArtistEvent {...props} groupIds={groupIds} />
        )}
      />
    </>
  );
};
