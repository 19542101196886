import { useUser } from "auth";
import { useQueryParams } from "Hooks";
import { Redirect, Route, Switch } from "react-router-dom";
import { SessionLayout } from "./Layout";
import { SESSION_PATHS } from "./paths";
import { useEffect, useLayoutEffect } from "react";
import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import { AppLoading } from "melodies-source/AppLoading";
import styled from "styled-components";
import { LoginWithMFA } from "./LoginWithMFA";

export const SessionRouter = () => {
  const { isLoggedIn } = useUser();
  const { redirect } = useQueryParams(["redirect"]);
  const params = new URLSearchParams();

  if (redirect) {
    params.append("redirect", redirect);
  }

  useEffect(() => {
    if (isLoggedIn) {
      const redirect = params.get("redirect")?.match(/^https?/i)
        ? sessionApi.go(decodeURIComponent(params.get("redirect")))
        : params.get("redirect") ?? "/";

      window.location.href = redirect;
    }
  }, [isLoggedIn, params]);

  useLayoutEffect(() => {
    document.getElementById("loader")?.remove();
  }, []);

  if (isLoggedIn) {
    return null;
  }

  const cssVariablesLoaded = getComputedStyle(
    document.getElementById("root")
  ).getPropertyValue("--variables-loaded");

  if (!cssVariablesLoaded) {
    return <AppLoading />;
  }

  return (
    <SessionLayout size={476} light={false} header={false}>
      <Container>
        <Switch>
          <Route path={SESSION_PATHS.LOGIN_WITH_MFA} component={LoginWithMFA} />
          <Redirect
            to={{
              pathname: "/",
              search: `?${params}`,
            }}
          />
        </Switch>
      </Container>
    </SessionLayout>
  );
};

const Container = styled.div`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;
