import backgroundLightImage from "assets/images/background-light.jpg";
import backgroundDarkImage from "assets/images/background.jpg";
import setLogoImage from "assets/images/logo-set-2x.png";
import { ComponentType } from "react";
import styled, { css } from "styled-components";
import { Footer } from "Components";

const darkBackground = css`
  background: linear-gradient(330deg, #1b0076 5%, transparent 100%),
    url(${backgroundDarkImage}) center / cover no-repeat, #190235;
  background-blend-mode: darken, normal, normal;
`;

const lightBackground = css`
  background: linear-gradient(180deg, transparent 50%, white 100%),
    linear-gradient(130deg, #e71e3d40 0%, transparent 40%),
    url(${backgroundLightImage}) center / cover no-repeat, lightgray;
`;

const Wrapper = styled.div<{ light?: boolean }>`
  min-height: 100vh;
  font-family: "Poppins";
  ${({ light, theme }) =>
    theme.custom
      ? css`
          background-color: var(--content-background-color);
        `
      : light
      ? lightBackground
      : darkBackground};

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

const Header = styled.div`
  background: #fff;
  box-shadow: 0px -1px 0px 0px #e6e9eb inset;
  height: 68px;
  padding: 0 20px;
`;

const HeaderLogo = styled.img.attrs(() => ({
  alt: "Set.Live",
  src: setLogoImage,
}))`
  width: 72px;
`;

const Card = styled.div`
  border-radius: 20px;
  background: var(--component-background-color);
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
`;

export const SetLogo = styled.img.attrs(() => ({
  alt: "Set.Live",
  src: setLogoImage,
}))`
  width: 130px;
  margin-bottom: 12px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 74px;
  }
`;

export const Title = styled.h1<{ size?: number }>`
  font-size: ${({ size }) => size ?? 38}px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--header-text-color);

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${({ size }) => (size ?? 38) * 0.7}px;
  }
`;

export const Description = styled.p<{ size?: number }>`
  font-size: ${({ size }) => size ?? 16}px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--text-color);
`;

export const Link = styled.div<{ size?: number }>`
  font-size: ${({ size }) => size ?? 16}px;
  font-weight: 600;
  color: var(--main-color);
  text-decoration: none;
  cursor: pointer;
`;

interface SessionLayout {
  light?: boolean;
  header?: boolean;
  size?: number;
}

export const SessionLayout: ComponentType<SessionLayout> = ({
  light = true,
  header = true,
  size = 400,
  children,
}) => {
  return (
    <Wrapper light={light} style={{ display: "flex", flexDirection: "column" }}>
      {header && (
        <Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <HeaderLogo />
        </Header>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <div style={{ padding: 20 }}>
          <Card style={{ maxWidth: size }}>{children}</Card>
        </div>
      </div>
      <StyledFooter withLogo={false} />
    </Wrapper>
  );
};

const StyledFooter = styled(Footer)`
  background: none !important;
  ${({ theme }) =>
    !theme.custom &&
    css`
      ${theme.mediaQueries.desktop} {
        padding: 20px 25px !important;
      }

      a,
      span {
        font-size: 11px;
        color: white;
      }
    `}
`;
