"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.towne = void 0;
const gtb_1 = require("../gtb");
const colors = {
    main: "#006610",
    secondary: "#00BD1D",
    tertiary: "#006610",
    background: "white",
    text: "#333333",
    headerText: "#343a40",
    secondaryText: "#666666",
    tertiaryText: "#999999",
    primaryButtonText: "white",
    primaryButtonHover: "#288b39",
    componentBackground: "#FFFFFF",
    border: "#ced4da",
    inputBorder: "#bdbdbd",
    hoverBackground: "#b2f2bb",
    boxShadow: "rgba(0,0,0,0.1)",
    selected: "#51cf66",
    contentBackground: "#E7F3E6",
    disabled: "#565656",
    loader: "#2f9e44",
    liveEventCardBackground: "#d3f9d8",
};
exports.towne = {
    company: {
        name: "Towne",
        logo: {
            url: "https://storage.googleapis.com/set-live-stage.appspot.com/Towne/logo.svg",
            description: "towne",
        },
        logoWhite: {
            url: "https://storage.googleapis.com/set-live-stage.appspot.com/Towne/logo-white.svg",
            description: "towne",
        },
    },
    event: {
        owner: {
            singular: "Program",
            plural: "Programs",
            singularWithArticle: "a Program",
        },
        type: {
            singular: "Event",
            plural: "Events",
            singularWithArticle: "an Event",
        },
        audience: {
            singular: "Attendee",
            plural: "Attendees",
            singularWithArticle: "an Attendee",
        },
    },
    footerLinks: [
        {
            text: "Towne Privacy Policy",
            url: "https://www.max.live/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ],
    fanAppUrl: `https://towne.live`,
    artistAppUrl: `https://dashboard.towne.live`,
    regionsCollection: "towne_regions",
    routes: gtb_1.gtb.routes,
    theme: {
        colors,
        background: colors.contentBackground,
        layout: {
            footer: {
                color: colors.text,
            },
        },
        pages: {
            event: {
                header: {
                    background: `linear-gradient(45deg, ${colors.main} 0%, ${colors.secondary} 100%), #20222A`,
                },
            },
            artist: {
                header: {
                    background: `linear-gradient(180deg, ${colors.main} 0%, ${colors.secondary} 100%), #20222A`,
                },
                eventCard: {
                    divider: {
                        color: colors.secondary,
                    },
                },
            },
        },
        charts: {
            donut: {
                colors: [
                    colors.main,
                    colors.secondary,
                    "#4d9458",
                    "#4dd161",
                    "#00470b",
                    "#008414",
                ],
            },
        },
    },
};
