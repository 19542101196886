import parse from "html-react-parser";
import styled from "styled-components";
import { P, Link, FlexRow, FlexColumn } from "notes";
import { useAuthContext, useBrandingContext } from "Context";
import { useUser } from "auth";
import { useHistory, useLocation } from "react-router-dom";
import { useCustomAppContext } from "Context/CustomAppContext";
import { DateTime } from "luxon";
import { SESSION_PATHS } from "Routes/Session/paths";

export const Footer = ({ className = "", ...props }) => {
  const { footer } = useBrandingContext();
  const {
    copyright,
    termsOfUseUrl,
    privacyPolicyUrl,
    hideDefaultLinks,
    addonLinks,
  } = footer || {};

  const { claims, signOut } = useUser();
  const { isAdmin } = claims || { isAdmin: false };
  const { isArtist } = useAuthContext() || {};
  const history = useHistory();
  const { customApp } = useCustomAppContext();
  const location = useLocation();
  const date = DateTime.now().toFormat("yyyy");

  const doSignOut = () => signOut("/");

  const isSessionPage = location.pathname.startsWith(SESSION_PATHS.ROOT);

  return (
    <FlexColumn className={className} {...props}>
      <FlexColumn xCenter style={{ padding: "24px" }}>
        {customApp ? (
          <>
            <FooterCopyright>
              © {date} {customApp?.company.name}
            </FooterCopyright>
            <FlexRow>
              {customApp.footerLinks.map((link) => (
                <FooterLink key={link.text} href={link.url} target="_blank">
                  {link.text}
                </FooterLink>
              ))}
            </FlexRow>
          </>
        ) : (
          <>
            <FooterCopyright>{parse(copyright || "")}</FooterCopyright>
            {!!addonLinks?.length && (
              <FlexRow>
                {addonLinks.map((item, index) => (
                  <FooterLink
                    key={index}
                    onClick={() => {
                      item.link.startsWith("/")
                        ? history.push(item.link)
                        : window.open(item.link, "_blank");
                    }}
                  >
                    {item.text}
                  </FooterLink>
                ))}
              </FlexRow>
            )}
            {!hideDefaultLinks && (
              <FlexRow>
                <FooterLink
                  onClick={() => {
                    privacyPolicyUrl?.startsWith("/")
                      ? history.push(privacyPolicyUrl)
                      : window.open(privacyPolicyUrl, "_blank");
                  }}
                >
                  Privacy Policy
                </FooterLink>
                <FooterLink
                  onClick={() => {
                    termsOfUseUrl?.startsWith("/")
                      ? history.push(termsOfUseUrl)
                      : window.open(termsOfUseUrl, "_blank");
                  }}
                >
                  Terms of Use
                </FooterLink>
              </FlexRow>
            )}
            <FlexRow stretch style={{ marginTop: "8px" }}>
              <FooterLink
                onClick={() => window.open("https://about.set.live", "_blank")}
              >
                Artists
              </FooterLink>
              <FooterLink
                onClick={() =>
                  isAdmin || isArtist
                    ? doSignOut()
                    : signOut("/").catch(() => {})
                }
              >
                {isArtist || isAdmin ? "Logout" : "Login"}
              </FooterLink>
              <FooterLink onClick={() => history.push("/contact")}>
                Contact Us
              </FooterLink>
            </FlexRow>
          </>
        )}
        {isAdmin &&
          (process.env.REACT_APP_GIT_SHA ||
            process.env.REACT_APP_RELEASE_VERSION) && (
            <FooterVersion>
              <span style={{ paddingRight: "5px" }}>Build</span>
              {[
                process.env.REACT_APP_RELEASE_VERSION,
                process.env.REACT_APP_GIT_SHA &&
                  `#${process.env.REACT_APP_GIT_SHA}`,
              ]
                .filter((v) => !!v)
                .join(" | ")}
            </FooterVersion>
          )}
      </FlexColumn>
      {!isSessionPage && <FooterLanguageContainer />}
    </FlexColumn>
  );
};

export const FooterLanguageContainer = styled(FlexRow)`
  border-top: 1px solid var(--border-color);
  flex: 0 0 40px;
  padding: 0 24px;
  width: 100%;
`;

export const FooterLink = styled(Link)`
  font-weight: 600;
  font-size: 12px;
  text-decoration: none !important;
  color: ${({ theme }) =>
    theme.custom?.layout.footer.color || "var(--text-color)"};
  & + & {
    margin-left: 16px;
  }
  &,
  &:focus {
    color: ${(props) => props.theme?.branding?.lobby?.colors?.footerLinks};
  }
  &:hover {
    color: inherit;
  }
`;

export const FooterCopyright = styled(P)`
  color: var(--tertiary-text-color);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const FooterVersion = styled(P)`
  color: var(--tertiary-text-color);
  font-size: 11px;
  font-weight: 200;
  margin-top: 4px;
`;
