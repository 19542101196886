import React, { useState } from "react";
import { MultiFactorResolver, TotpMultiFactorGenerator } from "firebase/auth";
import { useFormik } from "formik";
import * as yup from "yup";
import { yupRequired } from "Utils/yup";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Body1, H2 } from "melodies-source/Text";
import { FirebaseError } from "firebase/app";
import { TextInput } from "melodies-source/TextInput";
import { Button } from "melodies-source/Button";
import { useUser } from "auth";

const INITIAL_VALUES = { code: "" };

const VALIDATION_SCHEMA = yup.object().shape({
  code: yupRequired.matches(/^\d{6}$/, "Invalid code"),
});

export const TotpMFA: React.FC = () => {
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const { mfaResolver } = useUser();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleVerify,
  });

  async function handleVerify() {
    try {
      setVerifyCodeLoading(true);
      const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
        mfaResolver.hints[0].uid,
        formik.values.code
      );
      await mfaResolver.resolveSignIn(multiFactorAssertion);
    } catch (err) {
      const error = err as FirebaseError;
      switch (error.code) {
        case "auth/invalid-verification-code": {
          toast.error("Invalid code");
          break;
        }
        case "auth/code-expired": {
          toast.error("Code has expired");
          break;
        }
        case "auth/totp-challenge-timeout": {
          toast.error("Time expired, login again");
          break;
        }
        default: {
          console.error(err);
          toast.error("There was an error");
        }
      }
    } finally {
      setVerifyCodeLoading(false);
    }
  }

  return (
    <Container>
      <H2>TOTP Authentication</H2>
      <Body1>Enter the 6-digit code from your authenticator app. </Body1>
      <Form onSubmit={formik.handleSubmit}>
        <TextInput
          value={formik.values.code}
          onChange={formik.handleChange("code")}
          label="Code"
          type="number"
          {...(formik.errors.code && {
            hasError: true,
            helperText: formik.errors.code,
          })}
        />
        <Button type="submit" loading={verifyCodeLoading}>
          Verify
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${H2} {
    margin-bottom: 20px;
  }

  button {
    width: -webkit-fill-available;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
`;
