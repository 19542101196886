"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gtb = void 0;
const colors = {
    main: "#00b7ec",
    secondary: "#f36c23",
    tertiary: "#ee0c6d",
    background: "black",
    text: "#eeeeee",
    headerText: "white",
    secondaryText: "#cdcdcd",
    tertiaryText: "#999999",
    primaryButtonText: "black",
    primaryButtonHover: "#33c5f0",
    componentBackground: "#20222a",
    border: "black",
    inputBorder: "#eeeeee",
    hoverBackground: "#070a12",
    boxShadow: "rgba(0,0,0,0.4)",
    selected: "#b94100",
    contentBackground: "#070a12",
    disabled: "#565656",
    loader: "#00b7ec",
    liveEventCardBackground: "#070a12",
};
const isDev = process.env.NODE_ENV === "development";
exports.gtb = {
    company: {
        logo: {
            url: "https://storage.googleapis.com/set-live-stage.appspot.com/oie_8fFIqntHEOtt.png",
            description: "gtb",
        },
        name: "GTB",
    },
    event: {
        owner: {
            singular: "Program",
            plural: "Programs",
            singularWithArticle: "a Program",
        },
        type: {
            singular: "Event",
            plural: "Events",
            singularWithArticle: "an Event",
        },
        audience: {
            singular: "Attendee",
            plural: "Attendees",
            singularWithArticle: "an Attendee",
        },
    },
    footerLinks: [
        { text: "Ford Privacy Policy", url: "https://www.ford.com/help/privacy/" },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ],
    artistAppUrl: `https://${isDev ? "stage." : ""}gtbevents.live`,
    fanAppUrl: `https://${isDev ? "stage." : ""}fordevents.live`,
    regionsCollection: "gtb_regions",
    //routes: {
    //  paths: ["/", "/:eventId"],
    //  Component: Routes,
    //},
    theme: {
        colors,
        background: colors.contentBackground,
        layout: {
            footer: {
                color: "white",
            },
        },
        pages: {
            event: {
                header: {
                    background: colors.background,
                },
            },
            artist: {
                header: {
                    background: "linear-gradient(270deg,#EC106B 0%,#F26A25 100%),#20222A",
                },
                eventCard: {
                    divider: {
                        color: colors.secondary,
                    },
                },
            },
        },
        charts: {
            donut: {
                colors: [
                    "#00b7ec",
                    "#f36c23",
                    "#ed266d",
                    "#80dbf6",
                    "#f9b691",
                    "#f693b6",
                    "#005c76",
                    "#7a3612",
                    "#771337",
                ],
            },
        },
    },
};
