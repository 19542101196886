import { types, configs } from "@max/companies";
import { Routes } from "./companies/gtb/Routes";

configs.gtb.routes = {
  paths: ["/", "/:eventId"],
  Component: Routes,
};

export const companies: [RegExp, types.CustomApp][] = [
  [/fordevents.live$/, configs.gtb],
  [/towne.live$/, configs.towne],
  [/fizzevents.live$/, configs.fizz],
  [/aarp/, configs.aarp],
];
