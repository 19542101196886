import { useEffect, useState } from "react";

export const useCountdownText = (time: number) => {
  const [resendAt, setResendAt] = useState<Date>(null);
  const [resendIn, setResendIn] = useState<string>(null);

  const getResendCountdown = () => {
    const timeDiff = resendAt.getTime() - new Date().getTime();
    let minutes = Math.floor(timeDiff / time);
    let seconds = Math.floor((timeDiff - minutes * time) / 1000);
    return `${minutes < 10 ? "0" : ""}${Math.max(minutes, 0)}:${
      seconds < 10 ? "0" : ""
    }${Math.max(seconds, 0)}`;
  };

  const setResendCountdown = () => {
    const timer = getResendCountdown();
    setResendIn(timer);

    if (timer === "00:00") {
      setResendIn(null);
    } else {
      setTimeout(() => setResendCountdown(), 1000);
    }
  };

  const startCountdown = () => setResendAt(new Date(Date.now() + time));

  useEffect(() => {
    if (resendAt) setResendCountdown();
  }, [resendAt]);

  return { resendIn, startCountdown };
};
