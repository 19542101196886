"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.aarp = void 0;
const gtb_1 = require("../gtb");
const colors = {
    main: "#EC1300",
    secondary: "#79201A",
    tertiary: "#79201A",
    background: "white",
    text: "#333333",
    headerText: "#343a40",
    secondaryText: "#999999",
    tertiaryText: "#999999",
    primaryButtonText: "white",
    primaryButtonHover: "#a00d00",
    componentBackground: "#FFFFFF",
    border: "#ced4da",
    inputBorder: "#ced4da",
    hoverBackground: "#fde7e5",
    boxShadow: "rgba(0,0,0,0.1)",
    selected: "#51cf66",
    contentBackground: "#F9F8F6",
    disabled: "#565656",
    loader: "#EC1300",
    liveEventCardBackground: "#ffc4bf",
};
exports.aarp = {
    company: {
        name: "AARP",
        logo: {
            url: "https://cdn.aarp.net/content/dam/aarp/graphics/aarp_logos/120x30-aarp-header-logo-red.svg",
            description: "AARP",
        },
        logoWhite: {
            url: "https://cdn.aarp.net/content/dam/aarp/graphics/aarp_logos/120x30-aarp-header-logo-white.svg",
            description: "AARP",
        },
        icon: "https://www.aarp.org/etc/everywhere/images/favicon.ico",
    },
    event: {
        owner: {
            singular: "Program",
            plural: "Programs",
            singularWithArticle: "a Program",
        },
        type: {
            singular: "Event",
            plural: "Events",
            singularWithArticle: "an Event",
        },
        audience: {
            singular: "Attendee",
            plural: "Attendees",
            singularWithArticle: "an Attendee",
        },
    },
    footerLinks: [
        {
            text: "AARP Privacy Policy",
            url: "https://www.aarp.org/about-aarp/privacy-policy",
        },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ],
    artistAppUrl: `https://dashboard.aarpevents.org`,
    fanAppUrl: `https://aarpevents.org`,
    regionsCollection: "towne_regions",
    routes: gtb_1.gtb.routes,
    theme: {
        layout: {
            footer: {
                color: colors.text,
            },
        },
        colors,
        background: colors.contentBackground,
        pages: {
            event: {
                header: {
                    background: `linear-gradient(45deg, ${colors.main} 0%, ${colors.secondary} 100%), #20222A`,
                },
            },
            artist: {
                header: {
                    background: `linear-gradient(180deg, ${colors.main} 0%, ${colors.secondary} 100%), #20222A`,
                },
                eventCard: {
                    divider: {
                        color: colors.secondary,
                    },
                },
            },
        },
        charts: {
            donut: {
                colors: [
                    colors.main,
                    colors.secondary,
                    "#A50D00",
                    "#551612",
                    "#F25A4D",
                    "#A1635F",
                ],
            },
        },
    },
};
