import { Routes } from "Routes";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import {
  AnalyticsProvider,
  AppProvider,
  AuthProvider,
  BrandingProvider,
  LocationProvider,
  SearchProvider,
} from "Context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { UserProvider } from "auth";
import { Theme } from "melodies-source/Theme";
import { useCustomAppContext } from "Context/CustomAppContext";
import { CustomTheme } from "custom/types";
import { GlobalStyles } from "styled";
import { Loader } from "Components";
import { createPortal } from "react-dom";
import { SessionRouter } from "Routes/Session";
import { SESSION_PATHS } from "Routes/Session/paths";

export interface AppTheme {
  custom?: CustomTheme;
  [key: string]: any;
}

function App() {
  const { customApp } = useCustomAppContext();

  const appTheme: AppTheme = {
    custom: customApp?.theme,
  };

  return (
    // @ts-ignore
    <Theme theme={appTheme}>
      <GlobalStyles />
      {createPortal(<Loader />, document.getElementById("loader"))}
      <Router>
        <UserProvider>
          <HelmetProvider>
            <BrandingProvider>
              <Switch>
                <Route component={SessionRouter} path={SESSION_PATHS.ROOT} />
                <Route>
                  <AuthProvider>
                    <AppProvider>
                      <LocationProvider>
                        <AnalyticsProvider>
                          <SearchProvider>
                            <Routes />
                            <ToastContainer />
                          </SearchProvider>
                        </AnalyticsProvider>
                      </LocationProvider>
                    </AppProvider>
                  </AuthProvider>
                </Route>
              </Switch>
            </BrandingProvider>
          </HelmetProvider>
        </UserProvider>
      </Router>
    </Theme>
  );
}

export default App;
