import React, { useContext, useEffect, useState } from "react";
import {
  MultiFactorResolver,
  ParsedToken,
  getAuth,
  isSignInWithEmailLink,
  signOut,
  updatePassword,
} from "firebase/auth";
import {
  Authentication,
  CreateAuthenticationProps,
  LoadUserClaims,
  UserClaims,
  UserContextValue,
  UserProviderProps,
} from "./types";
import { getApp } from "firebase/app";
import { useAuthState } from "react-firebase-hooks/auth";
import { getUrl } from "Utils/getUrl";
import { AppLoading } from "melodies-source/AppLoading";
import { useHistory } from "react-router-dom";
import * as session from "./session";
import { toast } from "react-toastify";

const renewalErrorCodes = [
  "auth/expired-action-code",
  "auth/invalid-action-code",
];

function CreateAuthentication({
  firebaseApp,
  firebaseAuthDomain,
  loadClaims,
}: CreateAuthenticationProps = {}): Authentication {
  const auth = getAuth(firebaseApp);

  if (!firebaseAuthDomain) {
    firebaseAuthDomain = (firebaseApp || getApp())?.options.authDomain;
  }

  if (!firebaseAuthDomain) {
    console.error(
      "Could not determine firebaseAuthDomain.  Auth will likely fail."
    );
  }

  const UserContext = React.createContext<UserContextValue | undefined>(
    undefined
  );

  const UserProvider: React.FC<React.PropsWithChildren<UserProviderProps>> = ({
    children,
    blockRendering = true,
  }) => {
    const [user, userLoading] = useAuthState(getAuth());
    const [claims, setClaims] = useState<UserClaims>({ isAdmin: false });
    const [verifyingEmailLink, setVerifyingEmailLink] = useState(true);
    const [mfaResolver, setMfaResolver] =
      useState<Nullable<MultiFactorResolver>>(null);
    const history = useHistory();

    const redirectToLogin = (redirect?: string) => {
      const path =
        typeof redirect === "string" ? redirect : window.location.pathname;
      const loginUrl = getUrl({
        domain: firebaseAuthDomain,
        params: { redirect: getUrl({ path }) },
      });
      window.location.href = loginUrl;
    };

    const clearSession = async () => {
      await session.removeCookie();

      await signOut(auth).catch((error) =>
        console.error("Error signing out", error)
      );
    };

    const doSignOut = async (redirect?: string | boolean) => {
      const path = typeof redirect === "string" ? redirect : "/";

      await clearSession();

      if (firebaseAuthDomain !== window.location.origin && redirect !== false) {
        const signOutUrl = getUrl({
          domain: firebaseAuthDomain,
          params: {
            signOut: "true",
            redirect: getUrl({ path }),
          },
        });
        window.location.href = signOutUrl;
      }
    };

    const defaultLoadClaims: LoadUserClaims = (data) => {
      return {
        isAdmin: typeof data?.admin === "boolean" && data?.admin === true,
      };
    };

    const loadUserClaims = loadClaims
      ? loadClaims
      : (data: ParsedToken) => defaultLoadClaims(data);

    const verifyEmailLink = async () => {
      const { search, href } = window.location;
      const parsed = new URLSearchParams(search);
      const params = {
        email: parsed.get("email") || "",
        to: parsed.get("to"),
      };

      const isMagicLink = isSignInWithEmailLink(auth, href);

      // Try to sign-in the user
      const user = await session.resume(
        {
          href,
          firebaseError: (error) => {
            if (renewalErrorCodes.includes(error.code) && isMagicLink) {
              toast.error("Expired/Invalid link");
            } else {
              toast.error("There was an error");
            }
            setVerifyingEmailLink(false);
          },
        },
        history,
        setMfaResolver,
        firebaseApp
      );

      if (user) {
        // Automatic sign-in successful -- bail and wait for next call
        // to set the state
        console.debug("Call to session.resume succeeded");
        return;
      } else {
        setVerifyingEmailLink(false);
      }

      //if (isMagicLink && stopOnFailedSignInWithEmailLink === true) {
      //  // Don't go any further
      //  console.debug(
      //    "Magic link sign-in failed.  Stopping because stopOnFailedSignInWithEmailLink is true",
      //  );
      //}

      if (user) {
        if (isMagicLink && params.email && user.email !== params.email) {
          // User account mismatch
          console.debug("User account mismatch; clearSession");
          await clearSession();
          return;
        }

        if (isMagicLink) {
          console.debug(`Calling session.resume: ${href}`);
          await session.resume({ href }, history, setMfaResolver, firebaseApp);
        }

        // Set/update the session cookie
        console.debug("Calling session.init", user);
        await session.init(user);
      }

      if (
        user &&
        !user?.email &&
        !user.isAnonymous &&
        user.providerData.length === 0
      ) {
        // This looks pretty hacky, so here's why we do this.  The above call
        // to `session.resume` hits a backend function that checks the user's
        // session cookie and if valid returns a sign-in token.  The act of
        // generating this token will "upgrade" an anonymous account to a
        // "custom" account, however this "custom" account does not look
        // any different than an anonymous account.  So, if the account here
        // looks like one of these "custom" account, we force it into looking
        // like an anonymous account.
        console.debug("Forcing isAnonymous to true");
        Object.defineProperty(user, "isAnonymous", { value: true });
      }
    };

    const loading = userLoading || verifyingEmailLink;

    const value = {
      user,
      loading,
      claims,
      isLoggedIn: !!user,
      redirectToLogin,
      signOut: doSignOut,
      clearSession,
      updatePassword,
      mfaResolver,
      setMfaResolver,
    };

    useEffect(() => {
      if (user) {
        const isMagicLink = isSignInWithEmailLink(
          getAuth(),
          window.location.href
        );
        if (isMagicLink) history.push(window.location.pathname);
        setVerifyingEmailLink(false);
        user
          .getIdTokenResult()
          .then((res) => setClaims(loadUserClaims(res.claims)));
      }
    }, [user]);

    useEffect(() => {
      if (!user && !userLoading) {
        const isMagicLink = isSignInWithEmailLink(
          getAuth(),
          window.location.href
        );

        if (isMagicLink) {
          verifyEmailLink();
        } else {
          setVerifyingEmailLink(false);
        }
      }
    }, [userLoading]);

    return (
      <UserContext.Provider value={value}>
        {blockRendering && loading ? <AppLoading /> : children}
      </UserContext.Provider>
    );
  };

  const useUser = () => {
    const context = useContext(UserContext);

    if (context === undefined) {
      throw new Error("useUser must be used within a UserProvider");
    }

    return context;
  };

  return { useUser, UserContext, UserProvider };
}

export const { useUser, UserContext, UserProvider } = CreateAuthentication({
  firebaseAuthDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
});
